<template>
      <div class="modal-backdrop" @click.self="$emit('close')">
        <div class="modal">
          <h3 class="align-items-center">انتخاب کوین</h3>
          <div class="label-container">
            <div :class="fsearch? 'div-active':''" class="input-p2p">
              <label>
              <span class="d-flex gap-xss" :class="fsearch || fsearch ? 'span-active-p2p':''">
                <!-- <img src="../../assets/mag.png" alt="" width="12" height="12" class="align-self-center"> -->
              جستجو

              </span>
                <input
                    @focus="fsearch=true" v-model="regex" type="text">

              </label>
            </div>
          </div>
          <div class="coin-container d-flex flex-col">
            <div @click.prevent="selectCoin(coin.coin)" v-for="(coin,index) in searchCoin" class="row d-flex w100 gap-xss coin-select" :key="index">
              <img :src="'/coins/' + coin.code + '.png'" alt="" width="36" height="36">

              <span class="d-flex flex-col gap-xss">
                <span class="regular">{{ coin.persianName }}</span>
                <span class="small gray">{{ coin.code }}</span>
              </span>

            </div>
          </div>
        </div>
  
      </div>
</template>

<script>
export default {
  name: "CoinSelector",

  data() {
    return {
      fsearch: false,
      regex: '',
      coin: null,
    }


  },
  props: ['paymentType','coins'],
  computed: {
    searchCoin(){
      let allcoins
      if(this.regex){
      allcoins= this.coins.filter( coin => {
        if (coin.code.toUpperCase().includes(this.regex.toUpperCase()) || coin.persianName.includes(this.regex) || coin.englishName.toUpperCase().includes(this.regex.toUpperCase()) || coin.coin.toUpperCase().includes(this.regex.toUpperCase())) {

          return coin;
        }
      })
      }else{
        allcoins = this.coins
      }
      return allcoins
    }
  },
  methods: {

    async selectCoin(e) {
      let selectedCoin = await this.getSelectedWallet(e)
      this.$emit('coin', selectedCoin)
      this.$emit('close')
    },
    async getSelectedWallet(e){
      this.state.loading = true;
      const [res, ] = await this.$http.get(`/wallets/customer-addresses?walletType=EXCHANGE&coins=${e}`)
      if(res){
        return Object.entries(res).map( wallet => {
          wallet[1].relatedCoin = wallet[0]
          return wallet[1]
        })[0]
      }
    }
  },
}
</script>

<style lang="scss" scoped>




.coin-select{
  cursor: pointer;
}
.modal-backdrop{
  background-color: transparent !important;
}
.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 24px;
  min-height: 587px;
}

.coin-container {
  max-height: 360px;
  overflow: auto;

  .row {
    border-bottom: 1px solid var(--gray);
    padding: .5rem 0;
  }
}

.label-container {

  input {
    width: 70%;
  }

  img {
    width: 12px;
    height: 12px;
  }
}


</style>