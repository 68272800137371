<template>
  <div class="otp-container">
    <form @submit.prevent="openModal" class="otc d-flex flex-col justify-space-center align-items-center" @click.self="paymentDropDown = false">
      <div class="buttons d-flex w100 align-items-center">
        <div :class="buy ? 'btn-green':'btn-red'" class="swipper-box"></div>
        <button class="w100" @click.prevent="changeBuy(true)">خرید</button>
        <button class="w100" @click.prevent="changeBuy(false)">فروش</button>
      </div>


      <div class="balance-container d-flex w100 justify-space-between">
        <span> موجودی شما: </span>
        <span> {{( toman ? 'تومان' : 'تتر')+' ' +$toLocal(Math.abs(selectedPriceType.credit),(toman ? 0 : 2))}} </span>
      </div>
      <div class="price-container">
        <div class="price">
          <div @click="openPriceType" class="drop-down-container d-flex w100 justify-end">
            <inline-svg class="svg" :src="require('../../assets/Icons/arrow.svg')" />
            <span> {{toman ? 'تومان' : 'تتر'}} </span>
            <span> ({{toman ? 'TOMAN' : 'TETHER'}}) </span>
            <img :src="'/coins/'+ (toman ? 'TOMAN' : 'TETHER') +'.png'" alt="">
            <div v-if="PriceType" class="drop-down">
              <span @click="changePriceType(false)"> تتر </span>
              <span @click="changePriceType(true)"> تومان </span>
            </div>
          </div>

          <div class="label-container">
                    <div :class="fUnitPrice? 'div-active':''" class="input-p2p">
                        <label for="input">
                            <span :class="fUnitPrice || unitPrice ? 'span-active-p2p':''"> پرداختی شما </span>
                            <input @input="getUnitPrice" @focus="fUnitPrice=true" v-model="unitPrice" type="text">
                        </label>
                    </div>
          </div>
        </div>
        <div class="price">
          <div @click="openSelector" class="drop-down-container d-flex w100 justify-end">
            <inline-svg class="svg" :src="require('../../assets/Icons/arrow.svg')" />
            <span v-if="!selectedCoin.coin"> انتخاب کوین </span>
            <span v-if="selectedCoin.coin"> {{selectedCoin.persianName}} </span>
            <span v-if="selectedCoin.coin"> ({{selectedCoin.code}}) </span>
            <img v-if="selectedCoin.coin" :src="'/coins/'+ selectedCoin.coin +'.png'" alt="">
          </div>

          <div class="label-container">
                    <div :class="fAmount? 'div-active':''" class="input-p2p">
                        <label for="input">
                            <span :class="fAmount || amount ? 'span-active-p2p':''"> دریافتی شما </span>
                            <input @input="getAmount" @focus="fAmount=true" v-model="amount" type="text">
                        </label>
                    </div>
          </div>
        <div v-if="selectedCoin.coin" class="balance-container d-flex w100 justify-space-between">
          <span> مقدار قابل ارائه: </span>
          <span> {{selectedCoin.code+' ' +$toLocal((selectedCoin.credit),$decimal[selectedCoin.coin])}} </span>
        </div>
        </div>
      </div>
      <div class="shop-info-container">
        <div v-if="selectedCoin.coin" class="tag-price">
          <span> {{$toLocal(Math.abs(coinPrice),(toman ? 0 : 2)) + ' '+( toman ? 'تومان' : 'تتر') + ' ' +( toman ? '(TOMAN)':'(USDT)')}} </span>
          <span> ~ </span>
          <span> {{1 + ' '+selectedCoin.persianName + ' ' +selectedCoin.code}} </span>
        </div>
        <div v-if="selectedCoin.coin" class="tag-price">
          <span> {{'حداقل مقدار : '+selectedCoin.minAmountExchange+ ' ' +selectedCoin.code}} </span>
          <span> - </span>
          <span> {{'حداکثر مقدار : '+selectedCoin.maxAmountExchange+ ' ' +selectedCoin.code}} </span>
        </div>
      </div>



      <button :disabled="disable" class="w100" :class="{'btn-green':buy ,'btn-red':!buy}">

        {{ disable ? disable : (buy ? 'خرید' : 'فروش' )}}

      </button>


      <transition name="downtoup" >

        <CoinSelector v-if="isCoinSelector" :coins="coins" :paymentType="(toman ? 'TOMAN':'TETHER')" @close="isCoinSelector =false" @coin="getCoin"/>

      </transition>



      <FinalConfirm v-if="isOrder" @confirm="shop" :totalPrice="unitPrice" :Amount="amount" :coin="selectedCoin" :buy="buy"  :toman="toman" @close="isOrder=false"/>

    </form>
  </div>
</template>

<script>
import CoinSelector from "@/components/OTC/CoinSelector";
import FinalConfirm from "@/components/OTC/finalConfirm";

export default {
  name: "Exchange",
  components: {
    FinalConfirm,
    CoinSelector
    },
  data() {
    return{
      fAmount:false,
      fUnitPrice:false,



      unitPrice:'',
      amount:'',


      coinPrice:0,
      buy:false,
      toman:false,
      PriceType:false,
      selectedPriceType:'',
      isOrder:false,
      isCoinSelector:false,    
      coins:[],
      selectedCoin:'',  
    }
  },
  computed: {
    disable(){
      let err
        if(!this.selectedCoin.coin){
          err = 'کوین مورد نظر را انتخاب کنید'
        }else if(!this.amount && !this.unitPrice){
          err = 'میزان پرداختی یا دریافتی را وارد کنید'
        }else if(this.buy && (this.$S2N(this.unitPrice) > this.selectedPriceType.credit)){
          err = 'موجودی کافی نیست'
        }else if(!this.buy && (this.$S2N(this.amount) > this.selectedCoin.credit)){
          err = 'موجودی کافی نیست'
        }else if(!this.buy && (this.$S2N(this.amount) > this.selectedCoin.maxAmountExchange)){
          err = 'بیشتر از مقدار مجاز'
        }else if(!this.buy && (this.$S2N(this.amount) < this.selectedCoin.minAmountExchange)){
          err = 'کمتر از مقدار مجاز'
        } else {
          return false
        }
      return err
    }
  },
  methods: {
    openModal(){
      this.isOrder =true
    },
    async shop() {
                this.state.loading = true
                let temptData = {}
                temptData.amount = this.$S2N(this.amount)
                temptData.totalPrice = this.$S2N(this.unitPrice)
                temptData.destinationCoin = this.selectedCoin.coin
                temptData.unitPrice = this.$S2N(this.coinPrice)
                temptData.orderType = `EXCHANGE_${this.buy ? 'BUY' : 'SELL'}`
                temptData.marketType = this.selectedCoin.coin + '_' + (this.toman ? 'TOMAN' : 'TETHER')
                const res = await this.$axios.post(`/exchange/${this.buy ? 'buy' : 'sell'}`, temptData);
                res ? this.$error('','سفارش با موفقیت ثبت شد','success') : ''

            },
    changeBuy(val){
      this.buy = val ; 
      this.selectedCoin.coin ? this.getPrice() : '';
    },
    getUnitPrice(){
      if(this.selectedCoin.coin){
        this.unitPrice=this.$toLocal(this.unitPrice,(this.toman? 0:2))
        let amount = this.$S2N(this.unitPrice)/this.coinPrice
        this.amount = this.$toLocal(amount,this.$decimal[this.selectedCoin.coin])
      }else{
        this.unitPrice = ''
      }
    },
    getAmount(){
      if(this.selectedCoin.coin){
        this.amount=this.$toLocal(this.amount,this.$decimal[this.selectedCoin.coin])
        let uPrice = this.$S2N(this.amount) * this.$S2N(this.coinPrice)
        console.log(this.$S2N(this.coinPrice) , this.$S2N(this.amount), uPrice);
        this.unitPrice = this.$toLocal(uPrice,(this.toman? 0:2))
      }else{
        this.amount = ''
      }
    },
    getCoin(coin){
      let extraInfo = this.coins.filter(a => a.coin == coin.relatedCoin)[0]
      this.selectedCoin = {...coin,...extraInfo}
      console.log(this.selectedCoin);
      this.getPrice();
    },
    openSelector(){
      this.isCoinSelector=true;
    },
    openPriceType(){
      this.PriceType= !this.PriceType;
    },
    changePriceType(val){
      this.toman = val
      this.tomanTetherWallet();
      this.getPrice();
    },
    async tomanTetherWallet() {
                if (this.state.token) {
                    const [res, ] = await this.$http.get(`/wallets/customer-addresses?walletType=EXCHANGE&coins=${this.toman ? 'TOMAN' : 'TETHER'}`)
                    if (res) {
                        this.selectedPriceType = res[this.toman ? 'TOMAN' : 'TETHER'];
                    }
                }
      },
    async getCoins() {
      this.state.loading = true;
      const res = await this.$axios.get('coins/details')
      this.coins = res.content
    },
    async getPrice() {
                const res = await this.$axios.get('coins/exchange-price/' + (this.toman? 'usd' : 'usdt') + '?includeWeeklyChange=false&toCurrencies=' + this.selectedCoin.coin)
                let price = res.find(a => a.destCoinSymbol == this.selectedCoin.coin);
                this.coinPrice = this.buy ? price.buyPrice : price.sellPrice;
    },
  },

  mounted() {
    this.tomanTetherWallet();
    this.getCoins();

  },
  created:function(){
            window.addEventListener("click",event => {
                if(!event.target.closest('.label-container')){
                    this.fUnitPrice=false
                    this.fAmount=false
                }
                
                if(!event.target.closest('.drop-down-container')){
                    this.PriceType = false
                }
                })
            }
}
</script>

<style lang="scss" scoped>
.shop-info-container{
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.downtoup-enter-active, .downtoup-leave-active {
  transition: transform .8s ease;
}

.downtoup-enter, .downtoup-leave-to {
  transform:translateY(1200px) ;
}
.tag-price{
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: end;
  align-items: center;
  width: 100%;
  span{
    color: var(--dark-grey);
    font-weight: 500;
    font-size: clamp(12px,1vw,15px);
  }
}
.price-container{
  display: flex;
  flex-direction: column;
  gap: 76px;
  width: 100%;
  .price{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
}
.label-container{
  width: 100%;
}
.drop-down-container{
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: max-content;
  img{
    width: 36px;
    height: 36px;
  }
  span{
    font-weight: 400;
    font-size: clamp(14px,1vw,16px);
    color: var(--a-default);
  }
  .drop-down{
    position: absolute;
    z-index: 10;
    width: 40%;
    background: var(--white);
    display: flex;
    flex-direction: column;
    gap: 12px;
    top: 40px;
    left: 0;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    padding: 12px;
    border-radius: 8px;
  }
}
.balance-container{
  span{
    font-size: clamp(12px,1vw,14px);
    font-weight: 400;
    color: var(--a-hover);
  }
}


.coin-item{
  cursor: pointer;
}
.label-container .input-p2p label span {
  width: 30%;
}
.svg{
  fill: var(--a-hover);
}
.otp-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px;
  background: var(--homepage-bg);
}



.otc {
  background: var(--white-darker);
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 12px;
  padding: 28px 24px;
  width: 100%;
  max-width: 500px;
  gap: 46px;
}

.buttons {
  position: relative;
  box-shadow: 0 0 2px rgba(140, 150, 153, 0.34);
  border-radius: 4px;
  width: 100%;
  height: 52px;
  padding: .5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .swipper-box{
    position: absolute;
    width: 50%;
    height: 80%;
    z-index: 1;
    transition: 500ms;
    transform: translate(0,0);
  }
  .btn-green{
    transform: translate(48%,0) !important;
  }
  .btn-red{
    transform: translate(-48%,0) !important;
  }

  button {
    transition: 700ms;
    height: 36px;
    z-index: 2;
  }
}

.coin-container {
  gap: 1rem;
}

.payment-container {
  position: relative;

  .drop-down {
    span{
      cursor: pointer;
    }
    position: absolute;
    padding: 8px;
    border-radius: 4px;
    top: 3em;
    width: 100%;
    max-width: 140px;
    left: 20px;
    background: white;
    z-index: 3;
    max-height: 150px;
    overflow: scroll;
  }
}

.submit {

  height: 44px;
}
@media only screen and(max-width:900px){
  .otp-container{
    padding: 120px 10px;
  }
}
</style>