<template>
<div class="modal-backdrop" @click.self="$emit('close')">
  <div class="modal">
    <div class="title regular bolder d-flex justify-center">
<span class="d-flex">
  <span>سفارش</span>
  <span :class="{'green':buy , 'red' :!buy}" >{{buy? 'خرید' : 'فروش'}}</span>

</span>
      <!-- <img src="../../assets/Icons/close.svg" alt="" @click.prevent="$emit('close')"> -->
    </div>
    <span style="text-align:center;" class="justify-center w100 small align-self-start gray">آیا از سفارش خود مطمئن هستید؟</span>
    <div class="d-flex justify-space-between small gray">
      <span>ارز</span>
      <span class="ltr">{{coin.persianName}} </span>
    </div>
    <div class="d-flex justify-space-between small gray">
      <span>نوع</span>
      <span :class="buy ? 'success' : 'fail'" class="ltr">{{buy ? 'خرید' : 'فروش'}} </span>
    </div>
    <div class="d-flex justify-space-between small gray">
      <span>مقدار</span>
      <span class="ltr">{{$toLocal(Amount,$decimal[coin.coin])}} </span>
    </div>
    <div class="d-flex justify-space-between small gray">
      <span>{{buy ? 'پرداختی' : 'دریافتی'}}</span>
      <span class="ltr">{{$toLocal(totalPrice,toman? $decimal['TOMAN']: $decimal['TETHER'])}} {{toman ? 'TOMAN':'USDT'}}  </span>
    </div>

    <button class="btn-primary  w100"   @click.prevent="confirm"> تایید نهایی</button>
  </div>


</div>
</template>

<script>
export default {
  name: "finalConfirm",
  props:['fee','buy','coin','Amount','totalPrice','toman'],
  methods: {
    confirm(){
      this.$emit('confirm',false)
      this.$emit('close',false)
    }
  },
}
</script>

<style lang="scss" scoped>


.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 24px;
}
button{
  height: 44px;
}

</style>